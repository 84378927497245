@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply text-white bg-button-primary hover:bg-[#FC4C00] active:bg-[#B93700] transition-[background-color] duration-300 ease-in-out;
  }
  .btn-primary:disabled {
    @apply bg-button-primary/50 text-white/50 cursor-not-allowed
  }

  .btn-secondary {
    @apply text-secondary bg-white hover:bg-[#FFEEC3] active:bg-[#FFE5A1] transition-[background-color] duration-300 ease-in-out;
  }
  .btn-secondary:disabled {
    @apply bg-white/50 text-secondary/50 cursor-not-allowed
  }

  .btn-tertiary {
    @apply text-secondary bg-[rgba(252,106,0,0.2)] hover:bg-[rgba(252,106,0,0.3)] active:bg-[rgba(252,106,0,0.4)] transition-[background-color] duration-300 ease-in-out;
  }
  .btn-tertiary:disabled {
    @apply bg-[rgba(252,106,0,0.2)]/50 text-secondary/50 cursor-not-allowed
  }
}

input {
  all: unset;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.p-inputnumber-input	{
  width: 100%;
  height: 100%;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
      stroke: #FFDFBD;
  }
  50% {
      stroke: #FFDFBD;
  }
}

.Toastify__toast-container--top-right {
  width: auto;
  padding: 0;
  right: 120px;
  top: 139px
}
.Toastify__toast-theme--light {
  background-color: rgba(100, 47, 17, 0.80);
}